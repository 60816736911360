<template>
	<div>
		<!--Banner section -->
		<section class="whyupbanner marginTop_fixed">
			<div class="container">
				<div class="row">
					<div class="col-md-12">
						<h1>Why <span>us</span></h1>
					</div>
				</div>
			</div>
		</section>
		<!--end-->
		<!--design banner-->
		<section class="designbanner">
			<div class="container">
				<div class="row">
					<div class="col-md-12">
						<h2 class="text-center">
							<img src="@/assets/images/logo.svg" alt="SEO Suite" />
						</h2>
					</div>
				</div>
			</div>
		</section>
		<!--end-->

		<section class="whyuscontent">
			<div class="container eight50">
				<div class="row">
					<div class="col-md-12">
						<h3>We are first <b>entrepreneurs</b> and then technicians.</h3>
						<p>
							<span class="largetext">In 2018,</span> back when we started our
							firsr website, we realized that any successful web project was up
							against major technical-operational challenges. So,<b>
								a good web project must score high on quality by producing a
								good site,
								<span class="orangecolor"
									>great visibility and excellent customer retention.</span
								></b
							>
						</p>

						<p class="text-center pt-5 pb-4">
							<img src="@/assets/images/light.svg" alt="" />
						</p>
						<h3>
							<b class="orangecolor">SEO Suite</b> was created to deliver these
							business solutions in a simple way.
						</h3>
						<p>
							Nowadays, a good business idea and a beautiful site no longer
							guarantee a good site that maintains high customer loyalty – no
							matter the ever-growing investment on paid search. <br />
							<b>Without</b> the help of SEO and SEM specialists, copywriters
							and digital PR. SEO Suite helps you to create new sales assets for
							your company in a sostainable and long-lasting way while retaining
							customers and increasing visibility.
						</p>
						<p>
							<span class="largetext">Independently</span> and without the need
							to contact external agencies, SEO Suite puts in the hands of
							digital entrepreneurs the professional techniques necessary to
							achieve optimal visibility and customer retention with ease, in an
							automated and intelligent way.
						</p>
						<p class="sc-m-1">
							Our professional tools are designed for non-technical staff,
							allowing you to focus on what matters to you, your business, and
							do so in the best possible way.
						</p>
						<p class="sc-m-1">
							With <b class="orangecolor">SEO Suite</b>, it’s like having the
							best Copywriters, SEO specialists and digital PR at your disposal,
							because we offer you the professional tools to do their job.
							Easily, Independently and Automatically.
						</p>
					</div>
				</div>
			</div>
		</section>

		<!--extra hot space-->
		<section class="why_extra">
			<div class="container eight50">
				<div class="row">
					<div class="col-md-6">
						<h3>There is no need to wait!</h3>
					</div>
					<div class="col-md-6">
						<a
							:href="`https://my.seosuite.io/${$i18n.locale}/signup`"
							target="_blank"
							class="callbtn"
							>Start now</a
						>
						<p>
							In just in a few clicks You will start automating professionally.
						</p>
					</div>
				</div>
			</div>
		</section>
		<!--end-->
	</div>
</template>

<script>
	import MainPage from "@/layouts/MainPage.vue";
	export default {
		//this for layout call
		created() {
			this.$parent.$emit("update:layout", MainPage);
		},
		//this for layout render
		render() {
			return this.$slots.default[0];
		},
		//Meta info title + description + others meta
		metaInfo() {
			return {
				title: "SEO Suite",
				htmlAttrs: {
					lang: this.$i18n.locale,
				},
				meta: [
					{ name: "description", content: "SEO Suite" },
					{ property: "og:title", content: "SEO Suite" },
					{ property: "og:site_name", content: "SEO Suite" },
					{ property: "og:type", content: "website" },
					{ name: "robots", content: "index,follow" },
				],
			};
		},
		//end meta info
	};
</script>

<style lang="scss" scoped></style>
